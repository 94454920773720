<template>
  <div class="user-profile-container">
    <vs-popup title="" :active.sync="showUserDetailsModal">
      <h4>Edit user details</h4>
      <form data-vv-scope="user-details" class="edit-user-details-form">
        <div class="vx-row">
            <vs-input
              v-validate="'required'"
              name="firstName"
              label="First name"
              v-model="user.firstName"
              class="w-full mt-5"
            />
            <span
              v-show="errors.has('user-details.firstName')"
              class="text-danger"
            >{{ errors.first('user-details.firstName') }}</span>
        </div>
        <div class="vx-row">
          <vs-input
            v-validate="'required'"
            name="lastName"
            label="Last name"
            v-model="user.lastName"
            class="w-full mt-5"
          />
          <span
            v-show="errors.has('user-details.lastName')"
            class="text-danger"
          >{{ errors.first('user-details.lastName') }}</span>
        </div>
        <div class="vx-row user-birthdate-form-control">
          <label for="userBirthdate">Birthdate</label>
          <datepicker class="user-birthdate" placeholder="Select Date"
                      format="dd/MM/yyyy"
                      v-model="user.birthDate"
                      id="userBirthdate">
          </datepicker>
          <input type="hidden" name="userBirthdate" v-validate="'required'" v-model="user.birthDate">
          <span
            v-show="errors.has('user-details.userBirthdate')"
            class="text-danger"
          >{{ errors.first('user-details.userBirthdate') }}
          </span>
        </div>
        <vs-button class="mt-6 ml-auto flex" @click.prevent="updateUserDetails">Save</vs-button>
      </form>
    </vs-popup>
    <vs-popup title="" :active.sync="showUserPasswordModal">
      <h4>Update user password</h4>
      <form data-vv-scope="user-password" class="update-user-password-form">
        <div class="vx-row">
          <vs-input
            v-validate="'required|min:6'"
            name="password"
            label="Password"
            type="password"
            v-model="user.password"
            class="w-full mt-5"
          />
          <span
            v-show="errors.has('user-password.password')"
            class="text-danger"
          >{{ errors.first('user-password.password') }}</span>
        </div>
        <div class="vx-row">
          <vs-input
            v-validate="'required|min:6'"
            name="confirmPassword"
            label="Confirm Password"
            v-model="user.confirmPassword"
            type="password"
            class="w-full mt-5"
          />
          <span
            v-show="errors.has('user-password.confirmPassword')"
            class="text-danger"
          >{{ errors.first('user-password.confirmPassword') }}</span>
        </div>
        <vs-button class="mt-6 ml-auto flex" @click.prevent="updateUserPassword">Save</vs-button>
      </form>
    </vs-popup>
    <section class="user-details">
      <div class="user-details-info">
        <div class="user-details-block my-details">
          <div class="user-details-title">
            MY DETAILS
          </div>
          <div class="user-details-subtitle">
            Feel free to edit any of your details below so your account is up to date.
          </div>
        </div>
        <div class="user-details-block user-birth">
          <div class="user-details-title">
            DETAILS
          </div>
          <div class="user-attr-name">NAME</div>
          <div class="user-attr-value">{{ currentUser.first_name }} {{ currentUser.last_name }}</div>
          <div class="user-attr-name">DATE OF BIRTH</div>
          <div class="user-attr-value">{{ currentUser.dob }}</div>
          <a @click="showEditUserDetailsModal" class="user-edit-attr-link">Edit</a>
        </div>
        <div class="user-details-block user-login-details">
          <div class="user-details-title">
            LOGIN DETAILS
          </div>
          <div class="user-attr-name">EMAIL ADDRESS</div>
          <div class="user-attr-value">{{ currentUser.emailAddress }}</div>
          <div class="user-attr-name password-attribute">PASSWORD</div>
          <div class="user-attr-value">***************</div>
          <a @click="showUpdatePasswordModal" class="user-edit-attr-link">Edit</a>
        </div>
        <div class="user-details-block membership-details">
          <div class="user-details-title">
            YOUR MEMBERSHIP ID
          </div>
          <div class="user-details-subtitle">
            NTC20216776543
          </div>
        </div>
        <div class="user-details-block log-me-out">
          <div class="user-details-title">
            LOGOUT FROM ALL WEB BROWSERS
          </div>
          <div class="user-details-subtitle">
            This will log you out from all web browsers you have used to access the Natecia website.
            To log in again, you'll have to enter your credentials.
          </div>
          <button class="log-me-out-button generic-button" @click="logout">LOG ME OUT</button>
        </div>
        <div class="user-details-block delete-account">
          <div class="user-details-title">
            MANAGE ACCOUNT
          </div>
          <button class="delete-account-button generic-button" @click="logout">DELETE ACCOUNT</button>
          <div class="user-details-subtitle">
            By deleting your account you will no longer be a member of the Natecia Membership
          </div>
        </div>
      </div>
      <profile-menu></profile-menu>
    </section>
  </div>
</template>

<script>

import ProfileMenu from '@/components/user/menu/ProfileMenu.vue';
import Datepicker from 'vuejs-datepicker';
import moment from "moment";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Details',
  data() {
    return {
      user: {
        birthDate: null,
        firstName: null,
        lastName: null,
        password: null,
        confirmPassword: null
      },
      showUserDetailsModal: false,
      showUserPasswordModal: false
    }
  },
  components: {
    ProfileMenu,
    Datepicker
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    },
    showEditUserDetailsModal() {
      this.user.firstName = this.currentUser.first_name;
      this.user.lastName = this.currentUser.last_name;
      this.user.birthDate = this.patientData.dob;
      this.showUserDetailsModal = true;
    },
    showUpdatePasswordModal() {
      this.showUserPasswordModal = true;
    },
    updateUserDetails() {
      const payload = {
        "firstName": this.user.firstName,
        "lastName": this.user.lastName,
        "dob": this.$moment(this.user.birthDate).format('YYYY-MM-DD'),
        "email": this.currentUser.email,
        "id": this.currentUser.id,
        "gender": this.patientData.sex,
        "name": `${this.patientData.first_name} ${this.patientData.last_name ? this.patientData.last_name : ''}`.trim(),
        "patient_name": `${this.patientData.first_name} ${this.patientData.last_name ? this.patientData.last_name : ''}`.trim(),
        "last_sync_on": moment().format('YYYY-MM-DD HH:mm:ss')
      };
      return new Promise(() => {
        this.$validator.validateAll('user-details').then(result => {
          if (result) {
            this.$vs.loading();
            this.$store.dispatch('updateUserDetails', payload)
              .then(() => {
                this.showUserDetailsModal = false;
                this.$vs.loading.close();
                window.location.reload()
              })
              .catch(err => {
                this.$vs.loading.close();
                console.error(err);
              });
          }
        });
      })
    },
    updateUserPassword() {
      const payload = {
        "password": this.user.password,
        "email": this.currentUser.emailAddress
      };
      return new Promise(() => {
        this.$validator.validateAll('user-password').then(result => {
          if (result) {
            if (this.user.password !== this.user.confirmPassword) {
              this.errors.add({
                scope: 'user-password',
                field: 'confirmPassword',
                msg: 'Password and Confirm Password must match'
              })
            } else {
              this.$vs.loading();
              this.$store.dispatch('updateUserPassword', payload)
                .then(() => {
                  this.showUserPasswordModal = false;
                  this.$vs.loading.close();
                })
                .catch(error => {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    title: 'Error',
                    text: error.response.data.message,
                    color: 'danger',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle'
                  })

                });
            }
          }
        });
      })
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.userProfile;
    },
    patientData() {
      return this.$store.state.auth.patientData;
    },
  },
  mounted() {
    this.user.firstName = this.currentUser.first_name;
    this.user.lastName = this.currentUser.last_name;
    this.user.birthDate = this.patientData.dob;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-profile-container {
  max-width: 1400px;
  margin: 0 auto;
  .user-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 35px;
    .user-details-info {
      width: 70%;
    }
    .user-menu {
      width: 30%;
    }
    .user-details-block {
      .user-details-title {
        font-size: 24px;
        color: $green;
        font-weight: 700;
        padding-bottom: 5px;
      }
      .user-details-subtitle {
        font-size: 16px;
        color: $lightGreen;
      }
      margin-bottom: 50px;
      .user-attr-name {
        font-size: 16px;
        color: $green;
        text-transform: uppercase;
        padding-bottom: 5px;
        &.password-attribute {
          margin-top: 30px;
        }
      }
      .user-attr-value {
        font-size: 16px;
        color: $lightGreen;
        text-transform: uppercase;
        padding-bottom: 5px;
      }
      .user-edit-attr-link {
        text-decoration: underline;
        color: $green;
        font-size: 16px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .delete-account-button, .log-me-out-button {
      border-radius: 0;
      width: 325px;
      padding-top: 14px;
      padding-bottom: 14px;
      &.log-me-out-button {
        margin-top: 40px;
      }
      &.delete-account-button {
        margin-bottom: 10px;
        background-color: $lightYellow;
        color: $green;
      }
    }
    .delete-account {
      margin-bottom: 100px;
    }
  }
}
.vs-popup--header {
  background-color: $green;
  border-radius: 5px 5px 0 0;
  .vs-popup--close--icon {
    border-radius: 50%;
  }
}
.edit-user-details-form {
  display: flex;
  flex-direction: column;
  min-height: 525px;
  .user-birthdate-form-control {
    display: flex;
    flex-direction: column;
    margin-top: 8px !important;
    label {
      padding-left: 5px;
      color: $green;
      font-size: 14px;
    }
    .user-birthdate {
      margin-top: 5px;
      :focus {
        box-shadow: 2px 4px 8px 0px rgba(34, 60, 80, 0.2);
      }
    }
    #userBirthdate {
      border: 1px solid $green;
      border-radius: 0;
      padding: 15px 10px;
    }
  }
  .user-birthdate-form-control {
    flex-grow: 1;
  }
}

</style>
